/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: "us-west-2",
    aws_appsync_graphqlEndpoint: "https://veks4r32gzgqlic74w7chle6lq.appsync-api.us-west-2.amazonaws.com/graphql",
    aws_appsync_region: "us-west-2",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_cognito_region: "us-west-2",
    aws_user_pools_id: "us-west-2_eDNxzdQWH",
    aws_user_pools_web_client_id: "7658og41qrh5ou7a7km5ouk799",
    aws_cognito_identity_pool_id: "us-west-2:57af4813-cb5f-4b98-a894-f29118c5525e",
    Auth: {
        region: "us-west-2",
        userPoolId: "us-west-2_eDNxzdQWH",
        userPoolWebClientId: "7658og41qrh5ou7a7km5ouk799",
        identityPoolId: "us-west-2:57af4813-cb5f-4b98-a894-f29118c5525e",
    },
    Storage: {
        AWSS3: {
            bucket: "reliance-sitebuilder-artifacts-prod",
            region: "us-west-2",
        },
    },
    mandatorySignIn: true,
    oauth: {
        domain: "prod.auth.reliancelitigation.com",
        scope: ["email", "profile", "openid"],
        redirectSignIn: "https://sitebuilder.reliancelitigation.com/",
        redirectSignOut: "https://sitebuilder.reliancelitigation.com/",
        responseType: "code",
    },
};

export default awsmobile;
